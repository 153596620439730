@import "global";

.section.project-showcase {
  height: auto;
}

.floating {
  display: none;
}

.project-showcase {
  &.section {
    overflow-x: unset;
  }

  .section__content {
    &__body-wrapper {
      display: flex;
      max-width: 1000px;
      margin: 0 auto;
      padding-bottom: 40px;
      flex-direction: column;

      &__title {
        flex: 1;

        h2 {
          margin: 0;
          @include font-size(44);
          font-family: $ffHeading;
          color: #fff;
          line-height: 1;
          font-weight: 800;
          background: -webkit-linear-gradient(128deg, rgba(156, 116, 255, 1) 0%, rgba(255, 255, 255, 1) 65%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          padding-bottom: 30px;
        }

        &--small {
          font-weight: 300;
        }
      }

      &__copy {
        display: none;
        flex: 1;
        margin-left: 0;
        padding-bottom: 40px;

        p {
          margin: 0;
          color: #fff;
          @include font-size(16);
          font-family: $ffBodycopy;
          color: #fff;
          line-height: 1.8;
        }
      }
    }

    &__box-wrapper {
      display: flex;
      margin: 0 auto;
      max-width: 1000px;
      position: relative;

      iframe {
        width: 100%;
        height: 100%;
        aspect-ratio: calc(708/426);
        max-width: 708px;
        max-height: 446px;
        border-style: solid;
        border-color: #9F84ED;
        border-width: 24px;
        border-radius: 24px 0;
        z-index: 1;
      }

      &__title {
        display: none;
      }

      &__decoration{
        &-ring {
          display: none;
        }

        &-sphere {
          display: none;

          &.small{
            display: none;
          }
        }

        &-halfring {
          display: none;
        }
      }
    }

    &__carousel {
      position: relative;
      max-width: 1100px;
      margin: 0 auto;

      &__title {
        flex: 1;
        text-align: center;
        margin-top: 70px;

        h3 {
          margin: 0;
          @include font-size(18);
          font-family: $ffHeading;
          color: #fff;
          line-height: 1;
          font-weight: 800;
          padding-bottom: 40px;
        }
      }
  
      &__mockup {
        @include relative();
        width: 100%;
        height: 50px;
        border-radius: 40px;
        max-height: 50px;
        overflow: hidden;
        margin: 0 auto;
        max-width: 100%;
        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
    
        .mockup {
          @include absoluteCenter();
          max-width: 100%;
          max-height: 100%;
          pointer-events: none;
        }
      }
  
      &__mockup-container {
        @include absolute($top: 1%, $left: 3%);
        @include rect(94%);
        overflow: hidden;
        border-radius: 50px;
     }
  
      &__mockup-wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 150px;
        height: 100%;
        //border-radius: 10px;
        transition: transform 1s ease-in-out;
      }
  
      &__item {
        @include rect(100%);
        @include relative($top: 4%);
        height: 94%;
        flex-basis: 100%;
        flex-shrink: 0;
        background-color: #5ea6e8;
        background-repeat: no-repeat !important;
        background-position: center !important;
      }
  
      &__nav {
          visibility: hidden;
          height: 0px;
          pointer-events: none;
      }
    }

    &__decoration{
      &-roundedrectangle {
        display: none;
      }
      &-roundedrectangle2 {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .section.project-showcase {
    margin: 50px 0;
  }
  
  .project-showcase {
    .section__content {
      &__body-wrapper {
        flex-direction: row;
        padding-bottom: 70px;

        &__copy {
          flex: 1;
          margin-left: 80px;
          padding-bottom: 0;
        }

        &__title {
          h2 {
            @include font-size(60);
          }
        }

        &__copy {
          display: none;

          p {
            @include font-size(18);
          }
        }
      }

      &__box-wrapper {

        iframe {
          max-width: 676px;
          max-height: 414px;
          border-width: 40px;
          border-radius: 40px 0;
        }
      }
    }

    .floating {
      pointer-events: none;
      display: block;

      &--circle {
        border-radius: 50%;
      }
    }

    .f1 {
      @include absolute($top: 50px, $left: -13px);
      width: 25px;
      height: 25px;
      background-color: #8353e3;
      z-index: 10;
    }

    .f2 {
      height: 100%;
      z-index: 1;
      @include absolute($top: -450px, $right: -250px);
    }
  }

  &__carousel {
    &__mockup {
      -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
      mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
    }
  }
}

@media screen and (min-width: 1200px) {
  .project-showcase {
    .section__content {

      &__box-wrapper {

        iframe {
          max-width: 708px;
          max-height: 446px;
          border-width: 56px;
          border-radius: 56px 0;
        }

        &__decoration{
          &-sphere {
            display: block;
            position: absolute;
            top: calc(28% - 230px);
            left: 850px;
            transform: translateX(-50%);

            &.small{
              display: block;
              width: 27px;
              height: 27px;
              top: calc(6% - 180px);
              transform: translateX(-50%);
            }
          }

          &-halfring {
            display: block;
            position: absolute;
            top: 0;
            left: 640px;
            transform: translateY(-230px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .project-showcase {
    .section__content {
      &__box-wrapper {
        &__title {
          display: block;
          position: absolute;
          bottom: 10%;
          right: 0;
          transform: translateX(50%);
          width: 356px;
          flex: 1;
          z-index: 0;

          h3 {
            margin: 0;
            @include font-size(52);
            font-family: $ffHeading;
            color: #fff;
            line-height: 1;
            font-weight: 800;
          }

          &--small {
            margin-left: 40px;
            font-weight: 300;
          }

          &--line {
            position: absolute;
            left: -20px;
            top: 25px;
            transform: translateX(-100%);
            width: 200px;
            height: 1px;
            background-color: #fff;
          }
        }
        
        &__decoration{
          &-ring {
            display: block;
            position: absolute;
            top: calc(20% + 150px);
            left: -118px;
          }
        }
      }
      
      &__decoration{
        overflow: hidden;

        &-roundedrectangle {
          display: block;
          position: absolute;
          top: 40%;
          right: -5%;
        }
        &-roundedrectangle2 {
          display: block;
          position: absolute;
          top: 40%;
          right: -5%;
          transform: translate(140px, 54px);
        }
      }
    }
  }
}